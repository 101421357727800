var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "27",
        viewBox: "0 0 25 27",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.1726 8.56042V11.8957H18.8597C19.1619 11.8957 19.4494 12.0149 19.6638 12.2197C19.8783 12.4246 19.9952 12.7105 19.9952 13.0059V16.022H18.6941V13.1632H6.35V16.022H5.04883V13.0059C5.04883 12.7105 5.17066 12.4294 5.38021 12.2197C5.58976 12.0101 5.88216 11.8957 6.18431 11.8957H11.8714V8.56042",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.8298 2.10373C12.7951 2.0698 12.7535 2.04944 12.7049 2.04944C12.6077 2.04944 12.5244 2.13087 12.5244 2.22588V3.73237C12.5244 3.82738 12.6077 3.90881 12.7049 3.90881H14.2388C14.2873 3.90881 14.329 3.88845 14.3637 3.85452C14.4331 3.78666 14.4331 3.67809 14.3637 3.61023L12.8298 2.10373Z",
          fill: "#717DC6",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.5281 0.46145C18.2227 0.169651 17.8132 0 17.3829 0H7.666C7.23568 0 6.82618 0.169651 6.52079 0.46145C6.2154 0.75325 6.04883 1.16041 6.04883 1.58115V7.91252C6.04883 8.33325 6.22234 8.73363 6.52079 9.03221C6.81924 9.3308 7.23568 9.49366 7.666 9.49366H11.6013H13.4545H17.3829C17.8132 9.49366 18.2227 9.32401 18.5281 9.03221C18.8335 8.74041 19.0001 8.33325 19.0001 7.91252V1.58115C19.0001 1.16041 18.8335 0.760036 18.5281 0.46145ZM15.384 7.39678C15.384 7.78358 15.0647 8.09574 14.6691 8.09574H10.3798C9.98417 8.09574 9.6649 7.78358 9.6649 7.39678V1.80509C9.6649 1.41828 9.98417 1.10612 10.3798 1.10612H12.5869C12.7743 1.10612 12.9617 1.18077 13.0936 1.3097L15.1758 3.34552C15.3076 3.47445 15.384 3.65767 15.384 3.8409V7.39678Z",
          fill: "#717DC6",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.75146 15.312H1.60331C0.716374 15.312 0 16.0124 0 16.8748V24.8415C0 25.7039 0.716374 26.4091 1.60331 26.4091H9.75146C10.1754 26.4091 10.5799 26.2471 10.8821 25.9469C11.1842 25.6515 11.3499 25.2608 11.3548 24.851C11.3548 24.8463 11.3548 24.8463 11.3548 24.8415V16.8748C11.3548 16.0124 10.6384 15.312 9.75146 15.312ZM6.79337 16.4508V25.2703H4.5614V16.4508H6.79337ZM3.39669 16.4508V25.2703H1.60331C1.33041 25.2703 1.16959 25.1131 1.16959 24.8463V16.8748C1.16959 16.608 1.33041 16.4508 1.60331 16.4508H3.39669ZM7.95809 16.4508H9.75146C10.0244 16.4508 10.1852 16.608 10.1852 16.8748V24.8415C10.1852 25.1083 10.0244 25.2656 9.75146 25.2656H7.95809V16.4508Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.3967 15.312H15.2486C14.3665 15.312 13.6453 16.0124 13.6453 16.8796V24.8463C13.6453 25.7087 14.3616 26.4139 15.2486 26.4139H23.3967C23.8207 26.4139 24.2252 26.2519 24.5273 25.9517C24.8295 25.6563 24.9952 25.2656 25 24.8558C25 24.851 25 24.851 25 24.8463V16.8748C25 16.0124 24.2837 15.312 23.3967 15.312ZM20.4386 16.4508V25.2703H18.2067V16.4508H20.4386ZM17.042 16.4508V25.2703H15.2486C14.9757 25.2703 14.8149 25.1131 14.8149 24.8463V16.8748C14.8149 16.608 14.9757 16.4508 15.2486 16.4508H17.042ZM21.6034 16.4508H23.3967C23.6696 16.4508 23.8305 16.608 23.8305 16.8748V24.8415C23.8305 25.1083 23.6696 25.2656 23.3967 25.2656H21.6034V16.4508Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }